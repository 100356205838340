import namlogo from "../../assets/img/namlogo.png";
import AlertDialog from "../../shared/AlertDialog";
import LoadingOverlay from "react-loading-overlay";
import Card from "react-bootstrap/Card";
import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Button from "react-bootstrap/Button";
import "./timesheet.scss";
import Modal from "react-bootstrap/Modal";
import { fetchHelper } from "../../helpers/fetchHelper";
import * as Utility from "../../utils/utility";
import { decryptItem, encryptItem } from "../../shared/Encrypt";
import {
    BASE_URL,isMask,mailMask,mailSchedule,
    CUSTOM_EMAIL_NOTIFICATION,UPDATE_CMSPMA,
    UpdateHearingData,
    GET_PENDINGCOUNTFORNEUTRAL,
    UPDATE_CMSTIMESHEET,
    isSyncBack,
    HEARING_DURATION_LIMIT,
    mailAccount,
    PMA_DETAILS,
} from "../../api/baseURL";
import DatePicker from "react-date-picker";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import { get } from "../../api/api";
import axios from "axios";
import CaseData from "./caseData";
import moment from "moment";
const Timehouroptions = [];
const Timeminuteoptions = [];
const tomorrow = new Date();
const HEARING_LIMIT = HEARING_DURATION_LIMIT / 60;
tomorrow.setDate(new Date().getDate() + 1);
class Timesheet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timesheets: [],
            isconfigloaded: false,
            isalert: false,
            totalDuration : 0,
            ismaxhearingtimereached: false,
            ishearingerror: false,
            hearingEndDateError: false,
            isLoading: false,
            ismaxtimereached: false,
            hearingDate: this.props.caseDetails.hearingDate,
            hearingStatus: this.props.caseDetails?.hearingStatus,
            futureHearingDate:
                this.props.caseDetails?.futureHearingDate !== null
                    ? new Date(this.props.caseDetails?.futureHearingDate)
                    : null,
            notes: this.props.caseDetails?.notes,
            msg: "",
            caseDetails: this.props.caseDetails,
            issubmitted: false,
            isPaid:
                this.props.caseDetails?.isPaid ||
                Utility.IsCaseClosed(this.props.caseDetails.caseStatus),
            hearingCode: this.props.hearingCode,
            isdisplayAlertError: false,
            reserveduration: this.props.caseDetails.hearingDuration * 60,
            caseReference: this.props.caseDetails.caseReference,
            openConfirmDialog:false,
            deleteMessage: "",
            isConfirmedDelete:false,
            decisionSentforSign:false,
        };
    }

    closeConfirmDialog = (value) => {
        this.setState({ openConfirmDialog: false });
        this.setState({ isConfirmedDelete: value });
        this.setState({ deleteMessage: "" });
    }

    openConfirmDialogAdditionalTimeSheet = () => {
        this.setState({ openConfirmDialog: true });
        if(this.state.timesheets[0].hearingStartTime!=null && this.state.timesheets[0].hearingEndTime!=null)
        {
            this.setState({
                ishearingerror: false               
            });
        }
        this.setState({ deleteMessage: `Are you sure you want to delete Additional Timesheet?`});
    }

    updatedisplayAlert = (e) => {
        this.setState({ isdisplayAlertError: false });
    };
    setConfig = () => {
        for (let i = 0; i <= 8; i++) {
            if (Timehouroptions.indexOf(i) < 0) {
                Timehouroptions.push(i);
            }
        }
        for (let i = 0; i <= 11; i++) {
            if (Timeminuteoptions.indexOf(i * 5) < 0) {
                Timeminuteoptions.push(i * 5);
            }
        }
        this.setState({ isconfigloaded: true });
    };


    //its required for deletetime sheet
    removeAdditinalTimeSheet = () => {
        this.setState({ timesheets: [this.state.timesheets[0]] });
        this.TotalSpenttimecheck(0,true);
    }

    validateTimesheet=()=>{
        // const { timesheets, ismaxtimereached } = this.state;
        // timeSheets.forEach((x) => {
        // });
    }

    setTimeSheets = () => {
        const { timeSheets } = this.props.caseDetails;
        const deafulttimesheet = {
            hearingStartTime:
                this.props.caseDetails?.hearingStartTime !== null
                    ? new Date(this.props.caseDetails.hearingStartTime)
                    : null,
            hearingEndTime:
                this.props.caseDetails?.hearingEndTime !== null
                    ? new Date(this.props.caseDetails.hearingEndTime)
                    : null,
            revDocTimehours: 0,
            revDocTimeminutes: 0,
            postMedTimehours: 0,
            postMedTimeminutes: 0,
            hearingDuration:
                this.props.caseDetails?.hearingStartTime !== null &&
                    this.props.caseDetails?.hearingEndTime !== null
                    ? Utility.diff_minutes(
                        new Date(this.props.caseDetails.hearingStartTime),
                        new Date(this.props.caseDetails.hearingEndTime)
                    )
                    : 0,
        };
        if (timeSheets === null || timeSheets === undefined) {
            this.setState({ timesheets: [deafulttimesheet] });
        } else {
            timeSheets.forEach((x) => {
                x["hearingStartTime"] = new Date(x["hearingStartTime"]);
                x["hearingEndTime"] = new Date(x["hearingEndTime"]);
            });
            if (timeSheets && timeSheets.length === 2) {
                this.setState({ timesheets: timeSheets });
            } else if (timeSheets && timeSheets.length === 0) {
                this.setState({ timesheets: [deafulttimesheet] });
            } else {
                this.setState({ timesheets: [...timeSheets] });
            }
        }
    };
    onOptionChangeHandler = (event, index) => {
        const { timesheets } = this.state;
        const obj = timesheets[index];
        obj[event.target.name] = parseInt(event.target.value);
        timesheets[index] = obj;
        this.setState({ timesheets: timesheets });
        this.TotalSpenttimecheck(index);
    };
    onChangeValue = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        });
        if (
            event.target.name === "hearingStatus" &&
            event.target.value !== "CONTINUED"
        ) {
            this.setState({
                futureHearingDate: "",
                notes: "",
            });
        }
    };

    convertDateTimeToUTCDateTime = () => {
        let utcTimeSheets = [];
        this.state.timesheets.forEach(function (element) {
            element.hearingStartTimeUTC = Utility.convertTimeZoneToUTC(element.hearingStartTime);
            element.hearingEndTimeUTC = Utility.convertTimeZoneToUTC(element.hearingEndTime);
            utcTimeSheets.push(element);
            console.log("hearingStartTime", Utility.convertTimeZoneToUTC(element.hearingStartTime))
            console.log("hearingEndTime", Utility.convertTimeZoneToUTC(element.hearingEndTime))
        });
        return utcTimeSheets;
    }

    Submit = () => {
        this.setState({ isLoading: true });
        const { hearingStatus, timesheets, notes, futureHearingDate } = this.state;

        this.props.caseDetails.caseStatus=this.state.hearingStatus=="CONTINUED"?this.state.hearingStatus:this.props.caseDetails.caseStatus;
        const url = `${UpdateHearingData}`;
        const req = {};
        req["caseCode"] = `${this.state.caseDetails["caseCode"]}`;
        req["hearingCode"] = `${this.state.hearingCode}`;
        req["id"] = this.props.caseDetails.id;
        req["timeSheets"] = this.convertDateTimeToUTCDateTime();

        req["notes"] = notes ? notes : "";
        req["hearingStatus"] = hearingStatus;
        req["futureHearingDate"] =
            futureHearingDate !== undefined &&
                futureHearingDate !== null &&
                !isNaN(Date.parse(futureHearingDate))
                ? Utility.AddingTimezoneOffsetToISO(futureHearingDate?.toISOString())
                : "";
        req["userCode"] = `${decryptItem("userid")}`;
        fetchHelper
            .put(url, req)
            .then((res) => {
                if (Utility.IsHearingOfficer()) {
                    get(
                        `${GET_PENDINGCOUNTFORNEUTRAL}?neutralEmail=${decryptItem("neutralsEmail")}&userCode=${decryptItem ("jCode")}`,
                        {
                            headers: {
                                Authorization: `Bearer ${localStorage.getItem("token")}`,
                            },
                        }
                    ).then((resp2) => {
                        localStorage.setItem("pendingTaskCount", `${resp2.data}`);
                    });
                }
            })
            .catch((err) => {
                this.setState({ isdisplayAlertError: true });
                console.log(err);
            });
            if (
                this.state.hearingStatus === "CONTINUED"
            ) 
            {
                this.props.caseDetails.hearingStatus=this.state.hearingStatus;
                this.props.caseDetails.caseStatus=this.state.hearingStatus;
                this.sendEmailNotificationForContinuedHearing();
            }
        //Call Azure function ..UPDATE_CMSTIMESHEET
        const getCalculateTotalTime = this.calculateTotalTime()
        const body = {
            caseCode: parseInt(this.state.caseDetails["caseCode"]),
            hearingCode: parseInt(`${this.props.hearingCode}`),
            updatedBy: parseInt(`${decryptItem("usercode")}`),
            updateDate: new Date(),
            timesheetStart:
           new Date(
                this.state.timesheets[0].hearingStartTime
            ).toLocaleTimeString("en-US"),
            timesheetEnd: 
            new Date(this.state.timesheets[0].hearingEndTime).toLocaleTimeString("en-US"),            
            localNotes: this.state.timesheets
            .map(function (item) {
                console.log(`Start Time:${
                    Utility.convertUTCDateToUserTimeZoneOfPMADecision(
                        Utility.convertTimeZoneToUTC(item.hearingStartTime))                        
                } End Time:${
                    Utility.convertUTCDateToUserTimeZoneOfPMADecision(
                        Utility.convertTimeZoneToUTC(item.hearingEndTime))} Doc Rev Time: ${item.revDocTimehours} hours ${item.revDocTimeminutes} minutes`);
                return `Start Time:${Utility.convertUTCDateToUserTimeZoneOfPMADecision(
                    Utility.convertTimeZoneToUTC(item.hearingStartTime)) } End Time:${
                        Utility.convertUTCDateToUserTimeZoneOfPMADecision(
                            Utility.convertTimeZoneToUTC(item.hearingEndTime))
                    } Doc Rev Time: ${item.revDocTimehours} hours ${item.revDocTimeminutes} minutes`;
            })
                .join("\n"),
            totalTime: (getCalculateTotalTime < this.state.reserveduration ? this.state.reserveduration : getCalculateTotalTime / 60).toFixed(2),
            postMediationTime:
                ((((this.state.timesheets
                    .map((x) => x.postMedTimehours)
                    .reduce((a, b) => a + b, 0)) * 60) + this.state.timesheets
                        .map((x) => x.postMedTimeminutes)
                        .reduce((a, b) => a + b, 0)) / 60).toFixed(2),
            reviewTime:
                ((((this.state.timesheets
                    .map((x) => x.revDocTimehours)
                    .reduce((a, b) => a + b, 0)) * 60) + this.state.timesheets
                        .map((x) => x.revDocTimeminutes)
                        .reduce((a, b) => a + b, 0)) / 60).toFixed(2)
        };
        body.localNotes=body.localNotes+ (this.state.hearingStatus === "CONTINUED"?"To Be Continued":"");
        body.hearingStatus=this.state.hearingStatus;
        body.continuedNotes=this.state.hearingStatus === "CONTINUED"?("Potential future hearing date: "+ 
        ((this.state.futureHearingDate !=='' && this.state.futureHearingDate!=null && this.state.futureHearingDate!==undefined)?
         this.state.futureHearingDate:'') +((this.state.notes !=='' && this.state.notes!=null && this.state.notes!==undefined)?
         "\n Notes: "+this.state.notes:'')):'';
        const config = {
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        if (isSyncBack) {
            axios
                .post(UPDATE_CMSTIMESHEET, body, config)
                .then((res2) => {
                    if (res2.status === 200) {
                        this.setState({ issubmitted: true, isLoading: false });
                        console.log("pendingTaskCount", res2);
                    }
                })
                .catch((error) => {
                    this.setState({
                        isdisplayAlertError: true,
                        issubmitted: true,
                        isLoading: false,
                    });
                });
                const PMASyncbody = {
                    caseCode: parseInt(this.state.caseDetails["caseCode"]),
                    hearingCode: parseInt(`${this.props.hearingCode}`),

                    caseStatus: this.state.hearingStatus === "CONTINUED"?"CONTINUED": "HEARD",
                    updatedBy: `${decryptItem("usercode")}`,
                    updateDate: new Date(),
                    decisionDate: new Date()
                };
                const config1 = {
                    headers: {
                        "content-type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                }; 
                axios
                    .post(UPDATE_CMSPMA, PMASyncbody, config1)
                    .then((res2) => {
                        if (res2.status === 200) {
                            const hearingDetails = JSON.parse(decryptItem("hearingDetails"));
                            encryptItem("hearingDetails", JSON.stringify(hearingDetails));
                            //document why this block is empty        
                        }
                    })
                    .catch((error) => {
                        const hearingDetails = JSON.parse(decryptItem("hearingDetails"));
                        encryptItem("hearingDetails", JSON.stringify(hearingDetails));
                    });
        } else {
            this.setState({ issubmitted: true, isLoading: false });
        }
        this.props.callBackreloadcasedetails();
        //=====================
    };
    componentDidMount() {
        this.setConfig();
        this.setTimeSheets();
        this.getTimeSheetDuration();
    }
    componentDidUpdate(){
        if(this.state.isConfirmedDelete){
            this.setState({isConfirmedDelete: false});
            this.removeAdditinalTimeSheet()
        }
    } 
    sendEmailNotificationForContinuedHearing = () => {
        {
          let targetEmail =
             isMask?
               mailMask:mailSchedule
               let targetAccountEmail =
             isMask?
               mailMask:mailAccount
             ; //chnaged to logged in user
    let subject=            "Case ${caseReference}: Set as continued";
    subject=subject.replace("${caseReference}",this.state.caseReference);
          const emailBody = {
            listEmailReceivers: [
              {
                receiverName: targetEmail,
                receiverEmail: targetEmail,
              },
            {
                receiverName:targetAccountEmail,
                receiverEmail:targetAccountEmail
            }
            ],
            emailSubject:subject,
            emailTemplate:
               `<!DOCTYPE html><html><body><table style="width:40%;border:1px solid gray;text-align:center;margin:auto;">
                                    <tr><td style="border:1px solid gray;font-size:26px;padding: 25px;">
                                    <img src="${BASE_URL}${namlogo}" width="310px"><br>NAM Neutrals Portal Communication</td></tr>
                                    <tr>    <td style="border:1px solid gray;font-size:18px;padding: 25px;background:#f2f2f2;text-align:left;">
                                    ${localStorage.getItem("hearingOfficerName")} has marked the case ${this.state.caseReference} as continued. Please <a href='${BASE_URL}'>sign in</a> to the Neutrals Portal to review the details.
                                    </br><br><br><br>
                                    <p style="text-align:left;">Thank you!<br>NAM Neutral Portal Customer Service<br/>+1 (800) 358-2550<br> </td></p>  </tr></table></body></html>`
                ,
            emailBodyVariables: {
              additionalProp1: "",
              additionalProp2: "",
              additionalProp3: "",
            },
            filename: "",
            cc: "",
            bcc: "",
          };
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          };
          axios
            .post(CUSTOM_EMAIL_NOTIFICATION, emailBody, config)
            .then((_res) => {
             setTimeout(() => {

             }, 30);
            })
            .catch((_error) => {
              setTimeout(() => {

             // setIsLoaded(false);
             }, 30);  //console.log("email sent")
            })
            .catch((_error) => {
              //console.log("email failed")
              setTimeout(() => {
                //setIsLoaded(false);

               }, 30);
            });
        }
      };
    handleAddContainer = () => {
        const obj = {
            hearingStartTime:
                this.props.caseDetails?.hearingStartTime !== null
                    ? new Date(this.props.caseDetails.hearingStartTime)
                    : null,
            hearingEndTime:
                this.props.caseDetails?.hearingEndTime !== null
                    ? new Date(this.props.caseDetails.hearingEndTime)
                    : null,
            revDocTimehours: 0,
            revDocTimeminutes: 0,
            postMedTimehours: 0,
            postMedTimeminutes: 0,
            hearingDuration:
                this.props.caseDetails?.hearingStartTime !== null &&
                    this.props.caseDetails?.hearingEndTime !== null
                    ? Utility.diff_minutes(
                        new Date(this.props.caseDetails.hearingStartTime),
                        new Date(this.props.caseDetails.hearingEndTime)
                    )
                    : 0,
        };
        let tempAddAditionalTimeSheet=[];
        tempAddAditionalTimeSheet.push(this.state.timesheets[0])
        tempAddAditionalTimeSheet.push(obj)

        this.setState({ timesheets: tempAddAditionalTimeSheet });
        this.TotalSpenttimecheck(1,false,tempAddAditionalTimeSheet)
    };
    onhearingStartTimeChange = (time, timeString, index) => {
        const { timesheets, ismaxtimereached } = this.state;
        const obj = timesheets[index];

        if (time != null) {
            const dt1 = time["$d"];
            if (obj["hearingStartTime"] === null) {
                obj["hearingStartTime"] = dt1;
                timesheets[index] = obj;
                this.setState({ timesheets: timesheets });
                this.TotalSpenttimecheck(index);
            } else {
                const dt2 = new Date(this.props.caseDetails?.hearingStartTime);
                const diff = Utility.diff_minutes(dt1, dt2);
                if (diff > 30) {
                    const msg = ismaxtimereached
                        ? "Total hearing time exceeds 9 hours."
                        : "Are you sure?";
                    //"Total time exceeds 9 hours. Are you sure?" : "Are you sure?"
                    this.setState({
                        timesheets: timesheets,
                        isalert: true,
                        timesheetindex: index,
                        tmphearingStartTime: dt1,
                        msg: msg,
                    });
                } else {
                    obj["hearingStartTime"] = dt1;
                    const dt3 = new Date(obj["hearingStartTime"]);
                    dt3.setMinutes(dt3.getMinutes() + obj["hearingDuration"]);
                    obj["hearingEndTime"] = dt3;
                    timesheets[index] = obj;
                    this.setState({ timesheets: timesheets });
                    this.TotalSpenttimecheck(index);
                }
            }
            if (obj["hearingDuration"] > HEARING_DURATION_LIMIT) {
                this.setState({
                    ishearingerror: false,
                    ismaxhearingtimereached: true,
                    hearingEndDateError: false
                });
            } else {
                this.setState({
                    ishearingerror: false,
                    ismaxhearingtimereached: false,
                    hearingEndDateError: false
                });
            }
            if (obj["hearingEndTime"] != null && obj["hearingEndTime"].toLocaleDateString("en-US") > time["$d"].toLocaleDateString("en-US")) {
                this.setState({
                    ishearingerror: true,
                    hearingEndDateError: true
                });
            }
        }
        else {
            obj["hearingStartTime"] = null;
            timesheets[index] = obj;
            this.setState({ ishearingerror: true, timesheets: timesheets });
            this.TotalSpenttimecheck(index);

        }
        //// Added code if End time is null set button disabled
        if (index === 1) {
            if (timesheets[0].hearingStartTime === null || timesheets[0].hearingEndTime === null) {
                this.setState({
                    ishearingerror: true
                    // ,hearingEndDateError: true
                });
            }
        }
        else if (index === 0) {
            if (timesheets[1]!=undefined && (timesheets[1].hearingStartTime === null || timesheets[1].hearingEndTime === null)) {
                this.setState({
                    ishearingerror: true
                    // ,hearingEndDateError: true
                });
            }
        }
    };
    getTotalTime = () => {
        const totalspentminutes = this.calculateTotalTime();
        return Utility.toHoursAndMinutes(totalspentminutes);
    };
    calculateTotalTime = () => {
        const { timesheets } = this.state;
        if (timesheets !== undefined && timesheets !== null) {
            const totalpostMedTimehours = timesheets
                .map((x) => x.postMedTimehours)
                .reduce((a, b) => a + b, 0);
            const totalrevDocTimehours = timesheets
                .map((x) => x.revDocTimehours)
                .reduce((a, b) => a + b, 0);
            const totalpostMedTimeminutes = timesheets
                .map((x) => x.postMedTimeminutes)
                .reduce((a, b) => a + b, 0);
            const totalrevDocTimeminutes = timesheets
                .map((x) => x.revDocTimeminutes)
                .reduce((a, b) => a + b, 0);
            const totalhearingDuration = timesheets
                .map((x) => x.hearingDuration)
                .reduce((a, b) => a + b, 0);
            let totalspentminutes =
                totalpostMedTimeminutes +
                totalrevDocTimeminutes +
                (totalhearingDuration < this.state.reserveduration ? this.state.reserveduration : totalhearingDuration) +
                (totalpostMedTimehours * 60) +
                (totalrevDocTimehours * 60);
            if (!this.props.caseDetails.caseType.trim().toLowerCase().includes("mediation")) {
                const arbitrationTime = totalhearingDuration + totalrevDocTimeminutes + (totalrevDocTimehours * 60)
                totalspentminutes = arbitrationTime < this.state.reserveduration ? this.state.reserveduration : arbitrationTime
            }
            return totalspentminutes;
        }
        return 0;
    };
    TotalSpenttimecheck = (index, removedAdditionalTimeSheet=false,addAditionalTimeSheet=[]) => {
        const { timesheets, isalert } = this.state;
        let tempLocalTimeSheet= removedAdditionalTimeSheet? [timesheets[0]] : (addAditionalTimeSheet.length===0? timesheets :addAditionalTimeSheet)
        const totalpostMedTimehours =  tempLocalTimeSheet
            .map((x) => x.postMedTimehours)
            .reduce((a, b) => a + b, 0);
        const totalrevDocTimehours = tempLocalTimeSheet
            .map((x) => x.revDocTimehours)
            .reduce((a, b) => a + b, 0);
        const totalpostMedTimeminutes = tempLocalTimeSheet
            .map((x) => x.postMedTimeminutes)
            .reduce((a, b) => a + b, 0);
        const totalrevDocTimeminutes = tempLocalTimeSheet
            .map((x) => x.revDocTimeminutes)
            .reduce((a, b) => a + b, 0);
        const totalhearingDuration = tempLocalTimeSheet
            .map((x) => x.hearingDuration)
            .reduce((a, b) => a + b, 0);
        let totalspentminutes =
            totalpostMedTimeminutes +
            totalrevDocTimeminutes +
            (totalhearingDuration < this.state.reserveduration ? this.state.reserveduration : totalhearingDuration) +
            totalpostMedTimehours * 60 +
            totalrevDocTimehours * 60;
        if (this.props.caseDetails.caseType.trim().toLowerCase().includes("arbitration")) {
            const arbitrationTime = totalhearingDuration + totalrevDocTimeminutes + (totalrevDocTimehours * 60)
            totalspentminutes = arbitrationTime < this.state.reserveduration ? this.state.reserveduration : arbitrationTime
        }
        if (totalspentminutes > 540) {
            this.setState({
                isalert: false,
                ismaxtimereached: true,
                timesheetindex: index,
                msg: "Total hearing time exceeds 9 hours.",
                //msg: "Total time exceeds 9 hours. Are you sure?"
            });
        }
         else {
            this.setState({ ismaxtimereached: false, timesheetindex: index , isalert: false});
        }
    };
    onhearingEndTimeChange = (time, timeString, index) => {
        const { timesheets } = this.state;
        const obj = timesheets[index]; 

        if (time !== null) {
            if (obj["hearingStartTime"] === null) {
                return;
            }
            if (
                time["$d"].toLocaleDateString("en-US") ==
                obj["hearingStartTime"].toLocaleDateString("en-US")
            ) {
                if (obj["hearingEndTime"] === null) {
                    obj["hearingEndTime"] = time["$d"];
                    obj["hearingDuration"] = Utility.diff_minutes(
                        obj["hearingEndTime"],
                        obj["hearingStartTime"]
                    );
                    timesheets[index] = obj;
                    this.setState({ timesheets: timesheets });
                    this.TotalSpenttimecheck(index);
                } else {
                    if (time["$d"] <= obj["hearingStartTime"]) {
                        // return;
                    } else {
                        obj["hearingEndTime"] = time["$d"];
                        obj["hearingDuration"] = Utility.diff_minutes(
                            obj["hearingEndTime"],
                            obj["hearingStartTime"]
                        );
                        timesheets[index] = obj;
                        this.setState({ timesheets: timesheets });
                        this.TotalSpenttimecheck(index);
                    }
                }
                if (obj["hearingDuration"] > HEARING_DURATION_LIMIT) {
                    this.setState({
                        ishearingerror: false,
                        ismaxhearingtimereached: true,
                        hearingEndDateError: false
                    });
                } else {
                    this.setState({
                        ishearingerror: false,
                        ismaxhearingtimereached: false,
                        hearingEndDateError: false
                    });
                }
            }
            else if (obj["hearingStartTime"] != null && obj["hearingStartTime"].toLocaleDateString("en-US") < time["$d"].toLocaleDateString("en-US")) {
                this.setState({
                    ishearingerror: true,
                    hearingEndDateError: true
                });
            }
        }
        else {
            obj["hearingEndTime"] = null
            timesheets[index] = obj;
            this.setState({ ishearingerror: true, timesheets: timesheets });
            this.TotalSpenttimecheck(index);
        }
        //// Added code if End time is null set button disabled
        if (index === 1) {
            if (timesheets[0].hearingEndTime === null || timesheets[0].hearingStartTime === null) {
                this.setState({
                    ishearingerror: true
                    // ,hearingEndDateError: true
                });
            }
        }
        else if (index === 0) {
            if (timesheets[1]!=undefined && (timesheets[1].hearingEndTime === null || timesheets[1].hearingStartTime === null)) {
                this.setState({
                    ishearingerror: true
                    // ,hearingEndDateError: true
                });
            }
        }
    };
    Show = (val, index) => {
        const { timesheets } = this.state;
        this.setState({
            timesheets: timesheets,
            isalert: val,
            tmphearingStartTime: null,
        });
        this.TotalSpenttimecheck(index);
    };
    onConfirm = (val, index) => {
        const { timesheets, tmphearingStartTime } = this.state;
        const obj = timesheets[index];
        obj["hearingStartTime"] = tmphearingStartTime
            ? tmphearingStartTime
            : obj["hearingStartTime"];
        const dt3 = new Date(obj["hearingStartTime"]);
        dt3.setMinutes(dt3.getMinutes() + obj["hearingDuration"]);
        obj["hearingEndTime"] = dt3;
        timesheets[index] = obj;
        this.setState({
            timesheets: timesheets,
            isalert: val,
            tmphearingStartTime: null,
        });
        if (obj["hearingDuration"] > HEARING_DURATION_LIMIT) {
            this.setState({
                ishearingerror: false,
                ismaxhearingtimereached: true,
                hearingEndDateError: false
            });
        } else {
            this.setState({
                ishearingerror: false,
                ismaxhearingtimereached: false,
                hearingEndDateError: false
            });
        }
        if (obj["hearingEndTime"].toLocaleDateString("en-US") > obj["hearingStartTime"].toLocaleDateString("en-US")) {
            this.setState({
                ishearingerror: true,
                hearingEndDateError: true
            });
        }
        this.TotalSpenttimecheck(index);
    };
    close = () => {
        this.setState({ issubmitted: false });
        const hearingDetails = JSON.parse(decryptItem("hearingDetails"));
        hearingDetails.pendingAt = 'timesheets'
        encryptItem("hearingDetails", JSON.stringify(hearingDetails));
        window.location.href = this.props.timesheetUrl;
        window.location.reload();
    };
    calculateTotalTimeSheet = (timesheets) => {
        if (timesheets !== undefined && timesheets !== null) {
            const totalpostMedTimehours = timesheets.postMedTimehours;
            const totalrevDocTimehours = timesheets.revDocTimehours;
            const totalpostMedTimeminutes = timesheets.postMedTimeminutes;
            const totalrevDocTimeminutes = timesheets.revDocTimeminutes;
            const totalhearingDuration = timesheets.hearingDuration;
            let totalspentminutes =
                totalpostMedTimeminutes +
                totalrevDocTimeminutes +
                totalhearingDuration +
                totalpostMedTimehours * 60 +
                totalrevDocTimehours * 60;

            const arbitrationTime = totalhearingDuration + totalrevDocTimeminutes + (totalrevDocTimehours * 60)
            totalspentminutes = arbitrationTime < timesheets.hearingScheduledDuration * 60 ? timesheets.hearingScheduledDuration * 60 : arbitrationTime


            return totalspentminutes;
        }
        return 0;
    };
    getTimeSheetDuration  = ()=>{
        let timesheetDuration = 0;
        const url = `${PMA_DETAILS}?hearingCode=${this.props.hearingCode}&caseCode=${this.props.caseDetails["caseCode"]}`;
        //setIsPreview(false);
        axios
            .get(url, {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            })
            .then((res) => {
             let sum = 0;
        if (res?.data?.timeSheets?.length > 0) {
            
            for (let tCal = 0; tCal < res?.data?.timeSheets?.length; tCal++) {
                let localVal = this.calculateTotalTimeSheet(res?.data?.timeSheets[tCal]);
                
                sum = sum + localVal;
            }
            
            timesheetDuration = (Utility.toHoursAndMinutes(sum));
            var decisionfileCheck = res?.data?.additionalDecisionDocuments.filter(x => { return x.isDecisionFile !== null && x.isDecisionFile });

            
           this.setState({"totalDuration":timesheetDuration,decisionSentforSign: ((res?.data?.decisionFileName !== null && res?.data?.decisionFileName  !== undefined && res?.data?.decisionFileName !==''))&&(res?.data?.envelopeId!=='' &&  res?.data?.envelopeId!==null &&  res?.data?.envelopeId!==undefined)});
       
        }
        if(res?.data!=null)
        {
            if (res?.data?.caseUnsettled === true && res?.data?.decisionFileName === null && (res?.data?.envelopeId !== '' && res?.data?.envelopeId !== null && res?.data?.envelopeId !== undefined)) {
                this.setState({ decisionSentforSign: (res?.data?.envelopeId !== '' && res?.data?.envelopeId !== null && res?.data?.envelopeId !== undefined) });
            
            }
            else {
                this.setState({ decisionSentforSign: ((res?.data?.decisionFileName !== null && res?.data?.decisionFileName !== undefined && res?.data?.decisionFileName !== '')) && (res?.data?.envelopeId !== '' && res?.data?.envelopeId !== null && res?.data?.envelopeId !== undefined) });
            }
       
        }
    });
    //console.log("sum",sum);
       // return timesheetDuration;
    }
    

    render() {
        const {
            timesheets,
            msg,
            hearingStatus,
            ismaxtimereached,
            issubmitted,
            isconfigloaded,
            isLoading,
            isalert,
            timesheetindex,
            futureHearingDate,
            notes,
            isPaid,
        } = this.state;
        return (
            <>
            
                {isconfigloaded && (
                    <LoadingOverlay
                        active={isLoading}
                        spinner
                        text="Please wait working on your request..."
                    >
                        <>
                            <div className="row casestructure">
                                <CaseData caseDetails={this.state.caseDetails} />
                            </div>
                            <div className="timesheetSubmitDiv" style={{marginTop: "-84px",marginBottom: "75px"}}>                                
                                {this.state.caseDetails.isTimeSheetSubmitted?(
                                <div className="action-btns" style={{paddingBottom: "10px",paddingRight: "100px",paddingTop: "5px"}}><button class="float-right demobtn2" disabled={true}>
                                            <i class="fa fa-check-circle" aria-hidden="true"></i>                                            
                                                 {this.state.caseDetails.isTimeSheetSubmitted? "Timesheet Submitted" : "Decision Sent for Sign"}                                           

                                        </button></div>
                                ):("")}
                                   
                                </div>
                                <div className="clearfix"></div>
                            <div className="row" style={{ marginTop: "1.5rem" }}>
                                <div className="mb-1 col-sm-9 col-12 float-start text-start">
                                    <p className="font-weight-bold" style={{ marginBottom: "0" }}>
                                        Please fill in the below details to submit your timesheet
                                    </p>
                                   
                                </div>
                               
                               
                                <div className="col-sm-3 col-12 pull-right">
                                    <div style={{ marginRight: "9%" }}>
                                        <div>Total time spent on this case</div>
                                        <span
                                            className="font-weight-bold"
                                            style={{ color: "#337AB7" }}
                                        >
                                            {/* {this.state.totalDuration} */}
                                            {Utility.toHoursAndMinutes(this.props.caseDetails.caseDuration)}
                                        </span>
                                        <AccessTimeIcon />
                                    </div>
                                </div>
                            </div>
                            <div className="displaytimezone">
                                <div className="eventList">
                                    <p>All times in {Utility.userSpecificTimeZone()}</p>
                                </div>
                            </div>
                            <Card className="timesheetCard">
                                <Card.Body>
                                    <div className="row">
                                        <div className="col-md-9 col-12 times">
                                            <>
                                                {issubmitted && (
                                                    <Modal show={issubmitted} onHide={() => this.close()}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Timesheet</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            Timesheet has been submitted successfully
                                                        </Modal.Body>
                                                        <Modal.Footer>
                                                            <Button
                                                                variant="secondary"
                                                                onClick={() => this.close()}
                                                            >
                                                                Close
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                                )}
                                                {isalert && !ismaxtimereached && msg!=="Total hearing time exceeds 9 hours." && (
                                                    <Modal
                                                        show={isalert}
                                                        onHide={() => this.Show(false, timesheetindex)}
                                                    >
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Confirm Hearing Time</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>{msg}</Modal.Body>
                                                        <Modal.Footer>
                                                            <Button
                                                                variant="secondary"
                                                                onClick={() => this.Show(false, timesheetindex)}
                                                            >
                                                                Close
                                                            </Button>
                                                            <>
                                                                {!ismaxtimereached && (
                                                                    <Button
                                                                        variant="primary"
                                                                        onClick={() =>
                                                                            this.onConfirm(false, timesheetindex)
                                                                        }
                                                                    >
                                                                        Save Changes
                                                                    </Button>
                                                                )}
                                                            </>
                                                        </Modal.Footer>
                                                    </Modal>
                                                )}
                                            </>
                                            <>
                                                {}
                                                <div>
                                                    <div className="mb-1 row">
                                                        <label className="col-md-7 col-10 col-form-label float-start text-start">
                                                            Scheduled Start Time
                                                        </label>
                                                        <div
                                                            className="col-md-5 col-12"
                                                        >
                                                            <input
                                                                type="text"
                                                                readonly
                                                                className="form-control-plaintext"
                                                                value={new Date(this.props.caseDetails.hearingStartTimeUTCFromCMS).toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, "$1$3") +"  ("+Utility.userSpecificTimeZone()+")"}
                                                                disabled
                                                            /> 

                                                            {/* {(this.props.caseDetails.hearingStartTime.slice(10))}<TimePicker
                                        use12Hours
                                        format="h:mm a"
                                        disabled={true}
                                        minuteStep={5}
                                        defaultValue={
                                          this.props.caseDetails
                                            .hearingStartTime != null
                                            ? dayjs(
                                                new Date(
                                                  this.props.caseDetails.hearingStartTime
                                                )
                                              )
                                            : null
                                        }
                                    />     */}

                                                        </div>
                                                    </div>
                                                    <div className="mb-1 row">
                                                        <label className="col-md-7 col-12 col-form-label float-start text-start">
                                                            Scheduled Duration
                                                        </label>
                                                        <div
                                                            className="col-md-5 col-12"
                                                            style={{ paddingLeft: "0.8rem" }}
                                                        >
                                                            <input
                                                                type="text"
                                                                readonly
                                                                className="form-control-plaintext"
                                                                value={Utility.toHoursAndMinutes(parseFloat(this.props.caseDetails?.hearingDuration) * 60)}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="mb-1 row" style={{ position: "relative" }}>
                                                        <label className="col-md-7 col-12 col-form-label float-start text-start">
                                                            Payable Time
                                                        </label>
                                                        < div className="tooltip1 tooltipCirelc">i
                                                            < span className="tooltiptext" style={{ textAlign: 'left', paddingLeft: '2px', }}> This is the greater of the Scheduled Duration and the Actual Conference Duration.</span>
                                                        </div >
                                                        <div
                                                            className="col-md-5 col-12"
                                                            style={{ textAlign: "left" }}
                                                        >
                                                            <input
                                                                type="text"
                                                                readonly
                                                                className="form-control-plaintext"
                                                                value={this.getTotalTime()}
                                                                disabled
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr className="divider-timesheet" />
                                                {}
                                                {timesheets.map((timesheet, index) => {
                                                    return (
                                                        <>
                                                            <div key={`${index}` - `${"timesheet"}`}>
                                                                <div>
                                                                    <div className="mb-1 row">
                                                                        <label className="col-md-7 col-12 col-form-label float-start text-start">
                                                                            Conference Commenced
                                                                        </label>
                                                                        <div
                                                                            className="col-md-5 col-12"
                                                                            style={{ textAlign: "left" }}
                                                                        >
                                                                            <TimePicker
                                                                                use12Hours
                                                                                
                                                                                format="h:mm a"
                                                                                disabled={isPaid}
                                                                                //disabled={isPaid || this.state.decisionSentforSign}
                                                                                showNow={false} 
                                                                                minuteStep={5}
                                                                                defaultValue={
                                                                                    this.props.caseDetails
                                                                                        .hearingStartTime !== null
                                                                                        ? dayjs(
                                                                                            new Date(
                                                                                                this.props.caseDetails.hearingStartTime
                                                                                            )
                                                                                        )
                                                                                        : null
                                                                                }
                                                                                onChange={(time, timeString) =>
                                                                                    this.onhearingStartTimeChange(
                                                                                        time,
                                                                                        timeString,
                                                                                        index
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    timesheet.hearingStartTime !== null
                                                                                        ? dayjs(
                                                                                            new Date(
                                                                                                timesheet.hearingStartTime
                                                                                            )
                                                                                        )
                                                                                        : null
                                                                                }
                                                                            />


                                                                            {(timesheets.length === 2 && index === 1 //&& !(this.state.decisionSentforSign)
                                                                            ) && <span title="Delete Additional Timesheet" style={{ float: "right" }} onClick={this.openConfirmDialogAdditionalTimeSheet} >
                                                                                <i className="fa fa-trash"></i>
                                                                            </span>}                                                                          

                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div className="mb-1 row">
                                                                        <label className="col-md-7 col-12 col-form-label float-start text-start">
                                                                            Conference Ended
                                                                        </label>
                                                                        <div
                                                                            className="col-md-5 col-12"
                                                                            style={{ textAlign: "left" }}
                                                                        >
                                                                            <TimePicker
                                                                            
                                                                                use12Hours
                                                                                format="h:mm a"
                                                                                minuteStep={5}
                                                                                defaultValue={
                                                                                    this.props.caseDetails
                                                                                        .hearingEndTime !== null
                                                                                        ? dayjs(
                                                                                            new Date(
                                                                                                this.props.caseDetails.hearingEndTime
                                                                                            )
                                                                                        )
                                                                                        : null
                                                                                }
                                                                                //disabled={isPaid || this.state.decisionSentforSign}
                                                                                disabled={isPaid }
                                                                                showNow={false} 
                                                                                onChange={(time, timeString) =>
                                                                                    this.onhearingEndTimeChange(
                                                                                        time,
                                                                                        timeString,
                                                                                        index
                                                                                    )
                                                                                }
                                                                                value={
                                                                                    timesheet.hearingEndTime !== null
                                                                                        ? dayjs(
                                                                                            new Date(
                                                                                                timesheet.hearingEndTime
                                                                                            )
                                                                                        )
                                                                                        : null
                                                                                }
                                                                            />
                                                                        </div>                                                                        
                                                                    </div>
                                                                    <div className="mb-1 row">
                                                                        <label className="col-md-7 col-12 col-form-label float-start text-start">
                                                                            Conference Time
                                                                        </label>
                                                                        <div
                                                                            className="col-md-5 col-12"
                                                                            style={{ paddingLeft: "0.8rem" }}
                                                                        >
                                                                            <input
                                                                                type="text"
                                                                                readonly
                                                                                className="form-control-plaintext"
                                                                                value={Utility.toHoursAndMinutes(
                                                                                    timesheet.hearingDuration
                                                                                )}
                                                                                disabled
                                                                            />
                                                                        </div>
                                                                        {this.state.ishearingerror && this.state.hearingEndDateError &&
                                                                            <div className="mb-1 row">
                                                                                <label className="col-md-5 col-12 col-form-label float-start text-start"></label>
                                                                                <div
                                                                                    className="col-md-5 col-12 float-start text-start"
                                                                                    style={{ paddingLeft: "1.8rem" }}
                                                                                >
                                                                                    <span style={{ color: "red" }}>
                                                                                        Start and end time should be on same day.
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {/* As per discussion removed Validdation */}
                                                                        {/* {this.state.ishearingerror && !this.state.hearingEndDateError &&
                                                                            timesheet.hearingDuration >
                                                                            HEARING_DURATION_LIMIT && (
                                                                                <div className="mb-1 row">
                                                                                    <label className="col-md-5 col-12 col-form-label float-start text-start"></label>
                                                                                    <div
                                                                                        className="col-md-5 col-12 float-start text-start"
                                                                                        style={{ paddingLeft: "1.8rem" }}
                                                                                    >
                                                                                        <span style={{ color: "red" }}>
                                                                                            Reserved Time for Conference
                                                                                            cannot exceed {HEARING_LIMIT}{" "}
                                                                                            hours.
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            )} */}
                                                                    </div>
                                                                   
                                                                    <div className="mb-1 row mbMobile-1">
                                                                        <label className="col-md-7 col-12 col-form-label float-start text-start">
                                                                            Review of Documents Time (If any)
                                                                        </label>
                                                                        <div className="col-md-2 col-5 text-left">
                                                                            <select
                                                                            //disabled={isPaid || this.state.decisionSentforSign}
                                                                                disabled={isPaid}
                                                                                className="SelectField"
                                                                                value={timesheet.revDocTimehours}
                                                                                onChange={(event) =>
                                                                                    this.onOptionChangeHandler(
                                                                                        event,
                                                                                        index
                                                                                    )
                                                                                }
                                                                                name="revDocTimehours"
                                                                                id="revDocTimehours"
                                                                            >
                                                                                {Timehouroptions.map(
                                                                                    (option, index1) => {
                                                                                        return (
                                                                                            <option
                                                                                                key={`${index1}` - `${option}`}
                                                                                            >
                                                                                                {option}
                                                                                            </option>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </select>
                                                                            <label className="lb">Hrs.</label>
                                                                        </div>
                                                                        <div className="col-md-2 col-5 text-left">
                                                                            <select

                                                                                disabled={isPaid}
                                                                                //disabled={isPaid || this.state.decisionSentforSign}
                                                                                className="SelectField"
                                                                                value={timesheet.revDocTimeminutes}
                                                                                onChange={(event) =>
                                                                                    this.onOptionChangeHandler(
                                                                                        event,
                                                                                        index
                                                                                    )
                                                                                }
                                                                                name="revDocTimeminutes"
                                                                                id="revDocTimeminutes"
                                                                            >
                                                                                {Timeminuteoptions.map(
                                                                                    (option, index2) => {
                                                                                        return (
                                                                                            <option
                                                                                                key={`${index2}` - `${option}`}
                                                                                            >
                                                                                                {option}
                                                                                            </option>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </select>
                                                                            <label className="lb">Mins.</label>
                                                                        </div>
                                                                    </div>
                                                                    {}
                                                                    <>
                                                                        {this.props.caseDetails?.caseType.trim().toLowerCase().includes("mediation") && (
                                                                            <div className="mb-1 row mbMobile-1">
                                                                                <label className="col-md-7 col-12 col-form-label float-start text-start">
                                                                                    [For Non-PI Cases Only]: Post-Mediation Time (If any)
                                                                                </label>
                                                                                <div className="col-md-2 col-5 text-left">
                                                                                    <select
                                                                                        //disabled={isPaid || this.state.decisionSentforSign}
                                                                                        disabled={isPaid}
                                                                                        className="SelectField"
                                                                                        value={timesheet.postMedTimehours}
                                                                                        onChange={(event) =>
                                                                                            this.onOptionChangeHandler(
                                                                                                event,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        name="postMedTimehours"
                                                                                        id="postMedTimehours"
                                                                                    >
                                                                                        {Timehouroptions.map(
                                                                                            (option, index1) => {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={`${index1}` - `${option}`}
                                                                                                    >
                                                                                                        {option}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </select>
                                                                                    <label className="lb">Hrs.</label>
                                                                                </div>
                                                                                <div className="col-md-2 col-5 text-left">
                                                                                    <select
                                                                                        //disabled={isPaid || this.state.decisionSentforSign}
                                                                                        disabled={isPaid}
                                                                                        className="SelectField"
                                                                                        value={timesheet.postMedTimeminutes}
                                                                                        onChange={(event) =>
                                                                                            this.onOptionChangeHandler(
                                                                                                event,
                                                                                                index
                                                                                            )
                                                                                        }
                                                                                        name="postMedTimeminutes"
                                                                                        id="postMedTimeminutes"
                                                                                    >
                                                                                        {Timeminuteoptions.map(
                                                                                            (option, index2) => {
                                                                                                return (
                                                                                                    <option
                                                                                                        key={`${index2}` - `${option}`}
                                                                                                    >
                                                                                                        {option}
                                                                                                    </option>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </select>
                                                                                    <label className="lb">Mins.</label>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </>
                                                                    {}
                                                                    <>
                                                                    </>
                                                                </div>
                                                            </div>
                                                            {timesheets.length === 2 && (
                                                                <hr className="divider-timesheet" />
                                                            )}
                                                        </>
                                                    );
                                                })}
                                            </>
                                            <>
                                                {isPaid !== true &&
                                                    timesheets &&
                                                    timesheets.length !== 2 && (
                                                        <div
                                                            className="mb-1 row"
                                                            style={{
                                                                borderTop: "1px solid #c7c7c7",
                                                                padding: " 1rem 0.5rem",
                                                                borderBottom: "1px solid #c7c7c7",
                                                            }}
                                                        >
                                                            <div className="col-md-6 col-12 float-start text-start">
                                                                <button
                                                                    className="additionalbtn"
                                                                    onClick={this.handleAddContainer}
                                                                    //disabled = {this.state.decisionSentforSign}
                                                                >
                                                                    Add Additional time spent on the conference
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                            </>
                                            <div className="mb-1 row">
                                                <label className="col-md-7 col-12 col-form-label float-start text-start">
                                                    Is matter being continued?
                                                </label>
                                                <div className="col-md-4 col-12 float-start text-start">
                                                    <div onChange={this.onChangeValue}>
                                                        <input
                                                            type="radio"
                                                            //disabled={isPaid || this.state.decisionSentforSign}
                                                            disabled={isPaid}
                                                            value="CONTINUED"
                                                            name="hearingStatus"
                                                            checked={hearingStatus === "CONTINUED"}
                                                        />{" "}
                                                        Yes
                                                        <input
                                                            type="radio"
                                                           // disabled={isPaid || this.state.decisionSentforSign}
                                                            disabled={isPaid}
                                                            value="COMPLETED"
                                                            name="hearingStatus"
                                                            checked={hearingStatus !== "CONTINUED"}
                                                            style={{ marginLeft: "20px" }}
                                                        />{" "}
                                                        No
                                                    </div>
                                                </div>
                                            </div>
                                            <>
                                                {hearingStatus === "CONTINUED" && (
                                                    <>
                                                        <div className="mb-1 row">
                                                            <label className="col-md-7 col-12 col-form-label float-start text-start">
                                                                Future Hearing Date
                                                            </label>
                                                            <div className="col-md-4 col-12 float-start text-start">
                                                                <DatePicker
                                                                    locale="en-US"
                                                                    onKeyDown={(e) => {
                                                                        e.preventDefault();
                                                                    }}
                                                                    format="dd/MMM/yyyy"
                                                                    disabled={isPaid}
                                                                    onChange={(val) => {
                                                                        this.setState({ futureHearingDate: val });
                                                                    }}
                                                                    value={
                                                                        futureHearingDate instanceof Date &&
                                                                            !isNaN(futureHearingDate)
                                                                            ? futureHearingDate
                                                                            : null
                                                                    }
                                                                    minDate={tomorrow}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="mb-1 row">
                                                            <label className="col-sm-7 col-12 col-form-label float-start text-start">
                                                                Notes
                                                            </label>
                                                            <div className="col-sm-4 col-12 float-start text-start">
                                                                <input
                                                                    className="note"
                                                                    type="textarea"
                                                                    value={notes}
                                                                    name="notes"
                                                                    multiple
                                                                    onChange={this.onChangeValue}
                                                                    disabled={isPaid}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </>
                                           
                                        </div>
                                        <div className="col-sm-3 col-12">
                                            <div>Total time spent on this hearing</div>
                                            <span className="font-weight-bold">
                                                {this.getTotalTime()}
                                            </span>
                                            <AccessTimeIcon />
                                            {ismaxtimereached && 
                                            <Card className="noteexceeds">
                                                Note: This exceeds 9 hours.<br></br>
                                                Please confirm your entries are correct.
                                            </Card>}
                                            {/* {ismaxtimereached && this.state.timesheets.length === 2 &&
                                            <Card className="noteexceedsadditionalTimeSheet">
                                                Note: This exceeds 9 hours.<br></br>
                                                Please confirim your entries are correct.
                                            </Card>} */}
                                        </div>
                                    </div>
                                    <Card.Text></Card.Text>
                                </Card.Body>
                            </Card>
                            <>                           
                                {!isPaid && (
                                    <div className="border timesheet time">
                                        <div className="text-start">
                                            <Button
                                                className="additionalbtn2"
                                                //disabled={isPaid || this.state.ishearingerror || this.state.decisionSentforSign}
                                                disabled={isPaid || this.state.ishearingerror}
                                                onClick={this.Submit}
                                            >
                                                Submit Timesheet
                                            </Button>
                                        </div>
                                        {this.state.isdisplayAlertError === true && (
                                            <AlertDialog
                                                isdisplayAlert={false}
                                                isdisplayAlertError={this.state.isdisplayAlertError}
                                                updatedisplayAlertError={this.updatedisplayAlert}
                                            ></AlertDialog>
                                        )}
                                    </div>
                                )}
                            </>
                        </>
                        {this.state.openConfirmDialog && (
                            <AlertDialog
                                isdisplayAlert={false}
                                isdisplayAlertError={false}

                                openConfirmDialog={this.state.openConfirmDialog}
                                closeConfirmDialog={this.closeConfirmDialog}
                                deleteMessage={this.state.deleteMessage}
                            ></AlertDialog>
                        )}
                    </LoadingOverlay>
                )}
            </>
        );
    }
}
export default Timesheet;
